import React from "react";
import AuctionPropertySearchSales from "@templates/auction-search-results-template";
import Layout from "@components/layout";
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "@components/common/site/utils";

const SearchResult = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("sales", fullpath);

    return (
               <AuctionPropertySearchSales locationname="/property-auctions/for-sale/" 
            auctionType={"future"}   pcategorytype="auction" ptype="sales" ptypetag="for-sale" pstatustype="" fullpathone={fullpathone} fullpathname={fullpath}/>
    );
};

export default SearchResult;
